export enum ScreenViewTypeEnum {
  checklist = '/realty/checklist',
  ranking = '/realty/analytics/ranking',
  expert = '/realty/analytics/expert',
  map = '/map',
  home = '/home',
  todayRealty = 'TodayRealty',
  homeFeed = 'homeFeed', // 홈피드
}

export const ButtonClickMap = {
  checkList: 'checklist',
};

export type ButtonClickType = keyof typeof ButtonClickMap;

export const InstallAppDynamicLink = 'https://richgo.page.link/u9dy';
