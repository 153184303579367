import richLocalStorage from 'coreSrc/core/js/utils/richLocalStorage';

import type { UtmParameterStorageValue } from 'hostSrc/analytics/logEvent/utmParameters';
import type { FeedFilterType } from 'hostSrc/screens/HomeFeedScreen/HomeFeed';
import { TParcelRegion } from 'hostSrc/screens/danjis/ParcelHomeScreen/useParcelHomeScreen';

import { IBjdInfo } from 'jhSrc/types/bjd';

type KeysProps = {
  defaultRegionAnalyticBjdInfo: IBjdInfo;
  safetyJeonseTooltipLastClosedTime: string;
  feedFilterType: FeedFilterType;
  recommendDanjiSubscriptionClosedTime: string;
  lastMarketingPopupShownDate: string;
  homePopupAdClosedTime: string;
  lastSelectedParcelRegion: TParcelRegion;
  isClosedGuessRealTransactionPrice: boolean;
  mapIntroAdDismissOneMinuteTime: string;
  landMapIntroAdDismissOneMinuteTime: string;
  isClosedMemulChartMoreBtnTooltip: boolean;
  utmSource: UtmParameterStorageValue;
  utmMedium: UtmParameterStorageValue;
  utmContent: UtmParameterStorageValue;
  utmCampaign: UtmParameterStorageValue;
};

const isValidJSON = (jsonString: string): boolean => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};

const typedLocalStorage = {
  get<K extends keyof KeysProps>(key: K): KeysProps[K] | null {
    const result = richLocalStorage.getItem(key);
    if (result === null) {
      return null;
    }

    const canParseJson = isValidJSON(result);

    return canParseJson ? JSON.parse(result) : result;
  },

  set<K extends keyof KeysProps>(key: K, value: KeysProps[K] | null) {
    if (value === null) {
      return richLocalStorage.removeItem(key);
    } else {
      return richLocalStorage.setItem(key, JSON.stringify(value));
    }
  },

  remove<K extends keyof KeysProps>(key: K) {
    return richLocalStorage.removeItem(key);
  },
};

export default typedLocalStorage;
