/**
 * @prettier
 */
import { ReactNode, useEffect, useState } from 'react';

import Icon from './basicUI/Icon';

import styled, { keyframes } from 'styled-components';

const ANIM_DURATION = 200;

type LoadingOverlayProps = {
  overlayColor?: string;
  overlayOpacity?: number;
  align?: 'flex-start' | 'center' | 'flex-end';
  paddingTop?: number;
  visible?: boolean;
  children?: ReactNode;
};
function LoadingOverlay({
  overlayColor = 'rgba(255, 255, 255, 1)',
  overlayOpacity = 0.5,
  align,
  paddingTop,
  visible = true,
  children,
}: LoadingOverlayProps) {
  const [displayNone, setDisplayNone] = useState(false);
  useEffect(() => {
    let timeout;
    if (!visible) {
      timeout = setTimeout(() => {
        setDisplayNone(true);
      }, ANIM_DURATION + 10);
    } else {
      setDisplayNone(false);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [visible]);

  return (
    <SD_Container
      color={overlayColor}
      opacity={overlayOpacity}
      align={align}
      paddingTop={paddingTop}
      visible={visible}
      displayNone={displayNone}
    >
      <SD_Icon />
      {children}
    </SD_Container>
  );
}

type SD_ContainerProps = {
  color: string;
  opacity: number;
  align?: 'flex-start' | 'center' | 'flex-end';
  paddingTop?: number;
  visible: boolean;
  displayNone: boolean;
};
const SD_Container = styled.div<SD_ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  display: ${(p) => (p.displayNone && !p.visible ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: ${(p) => p.align ?? 'center'};
  align-items: center;

  padding-top: ${(p) => (p.align === 'flex-start' ? p.paddingTop ?? 50 : 0)}px;

  background-color: ${(p) => p.color};
  backdrop-filter: blur(3px);
  opacity: ${(p) => (p.visible ? p.opacity : 0)};
  transition: opacity ${ANIM_DURATION / 1000}s;
`;
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(36deg); }
  20% { transform: rotate(72deg); }
  30% { transform: rotate(108deg); }
  40% { transform: rotate(144deg); }
  50% { transform: rotate(180deg); }
  60% { transform: rotate(216deg); }
  70% { transform: rotate(252deg); }
  80% { transform: rotate(288deg); }
  90% { transform: rotate(324deg); }
  100% { transform: rotate(360deg); }
`;
const SD_Icon = styled(Icon).attrs({
  name: 'loading-ellipse',
})`
  animation: ${rotate} 0.6s ease-in-out;
  animation-iteration-count: infinite;
`;

export default LoadingOverlay;
