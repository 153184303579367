import FIREBASE_CONFIG from './FIREBASE_CONFIG';

import { Analytics } from 'firebase/analytics';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

let analytics: Analytics;

export default class RichgoAnalytics {
  private _analytics: Analytics;

  constructor() {
    if (analytics) {
      this._analytics = analytics;
    } else {
      const app = initializeApp(FIREBASE_CONFIG);
      this._analytics = getAnalytics(app);
    }
  }

  log(name: 'login'): void;
  log(name: 'sign_up'): void;
  log(name: 'partner', params: { type: string }): void;
  log(name: 'future_price_view_click'): void;
  log(name: 'device', params: { type: string }): void;
  log(name: string, params?: any) {
    switch (name) {
      case 'login':
        logEvent(this._analytics, name);
        break;

      case 'sign_up':
        logEvent(this._analytics, name);
        break;

      case 'partner':
        logEvent(this._analytics, name, params);
        break;

      case 'future_price_view_click':
        logEvent(this._analytics, name);
        break;

      case 'device':
        logEvent(this._analytics, name, params);
        break;

      default:
        console.warn('이벤트명으로 전달된 인자를 다시 확인해주세요');
        break;
    }
  }
}
