/**
 * @prettier
 */

import documentCookie from '../../coreSrc/core/js/utils/documentCookie';

import { RICHGO_API_ACCESS_TOKEN_KEY } from 'coreSrc/base/userSystemManager/user/authManager/const';
import { postApiActivityV2 } from 'hostSrc/apis/endpoints/activity/activity';

async function _analyticsLog(activity: string, meta?: any) {
  try {
    if (activity === 'session_start') {
      if (!('window' in globalThis)) return;
    }

    const headers = await createHeaders();
    const body = createBody(activity, meta ?? {});

    await postApiActivityV2(body, { headers: headers } as any);
  } catch (err) {
    console.error(err);
  }
}

async function createHeaders() {
  const headers = new Headers();

  headers.set('content-type', 'application/json');

  const accessToken = documentCookie.get(RICHGO_API_ACCESS_TOKEN_KEY);
  if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);

  const deviceId = sessionStorage.getItem('deviceId') || null;
  if (deviceId) headers.set('deviceId', deviceId);

  return headers;
}

function createBody(activity: string, meta: any) {
  const body = {
    activity,
    meta,
  };

  return body;
}

export default _analyticsLog;
