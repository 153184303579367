import { RICHGO_API_HOST } from 'hostSrc/_initDatas/env';

interface IReadAccessTokenPayload {
  refreshToken: string;
}

export const readAccessToken = async (payload: IReadAccessTokenPayload) => {
  const response = await fetch(`${RICHGO_API_HOST}/user/V2/access-token`, {
    headers: {
      Authorization: `Bearer ${payload.refreshToken}`,
    },
  });
  if (response.status !== 200) throw response;
  const json = await response.json();
  return json.result?.accessToken ?? '';
};
