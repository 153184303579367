import { ReactNode, isValidElement } from 'react';

import { PopupContainerProps, RouteOptions } from '../routeManager';
import AlertSkin from './skins/AlertSkin';
import ConfirmSkin from './skins/ConfirmSkin';
import { DialogAction } from './types';

import { isStyledComponent } from 'styled-components';

type RestArgs = [...DialogAction[], PopupContainerProps] | DialogAction[];

function isDialogAction(obj: unknown): obj is DialogAction {
  if (typeof obj !== 'object') {
    return false;
  }
  const keys = Object.keys(obj);
  return keys.includes('label') || keys.includes('onClick');
}

function getActionsAndContainerOptions(restArgs: RestArgs): {
  actions?: DialogAction[];
  containerOptions?: PopupContainerProps;
} {
  if (!restArgs || restArgs.length === 0) {
    return {};
  }

  const last = restArgs[restArgs.length - 1];

  if (isDialogAction(last)) {
    return { actions: restArgs as DialogAction[] };
  }

  return {
    actions: restArgs.slice(0, restArgs.length - 1) as DialogAction[],
    containerOptions: last,
  };
}

function useDialogReducer(router) {
  function open(title: string, content: string, ...restArgs: RestArgs): void;
  function open(
    render: () => ReactNode,
    containerOptions: PopupContainerProps,
    routeOptions?: RouteOptions
  ): void;
  function open(
    children: ReactNode[],
    containerOptions: PopupContainerProps,
    routeOptions?: RouteOptions
  ): void;
  function open(
    reactNode: ReactNode,
    containerOptions?: Omit<PopupContainerProps, 'children'>,
    routeOptions?: RouteOptions
  ): void;
  function open(...args): void {
    if ((typeof args[0] === 'string' || typeof args[1] === 'string') && args.length > 2) {
      const [title, message, ...restArgs] = args as [string, string, ...RestArgs];

      router.go('dialog', {
        title,
        message,
        ...getActionsAndContainerOptions(restArgs),
      });
      return;
    }

    if (typeof args[0] === 'function') {
      const [render, containerOptions, routeOptions] = args as [
        () => ReactNode,
        PopupContainerProps,
        RouteOptions
      ];
      router.go('dialog', { render, containerOptions }, routeOptions);
      return;
    }

    const [children, containerOptions, routeOptions] = args as [
      ReactNode[],
      PopupContainerProps,
      RouteOptions
    ];
    if (isValidElement(children)) {
      try {
        const name = getFunctionName(children);
      } catch (err) {
        console.error(err);
      }
    }

    router.go('dialog', { children, containerOptions }, routeOptions);
  }

  return {
    open,
    alert(title: string, message: string, ...args: RestArgs) {
      const { actions, containerOptions } = getActionsAndContainerOptions(args);
      router.go('dialog', {
        children: (
          <AlertSkin
            title={title}
            message={message}
            action={actions?.[0]}
          />
        ),
        containerOptions,
      });
    },
    confirm(title: string, message: string, ...args: RestArgs) {
      const { actions, containerOptions } = getActionsAndContainerOptions(args);
      router.go('dialog', {
        children: (
          <ConfirmSkin
            title={title}
            message={message}
            actions={actions}
          />
        ),
        containerOptions,
      });
    },
    // FIXME: kangseongofdk
    // - `options` 타입 수정
    close(options: { thenGo?: { key: string; data: Record<string, any> } }) {
      router.back(options);
    },
  };
}

export default useDialogReducer;

function getFunctionName(reactElement: React.ReactElement) {
  if (isStyledComponent(reactElement.type)) {
    return reactElement.type.displayName;
  } else if (reactElement.type instanceof Function) {
    return reactElement.type.name;
  } else {
    return 'unknown';
  }
}
