const isPc = (sys) => {
  // console.log('sys.router', sys.router);

  return (
    sys.router.asPath.split('#')[0] === '/pc' ||
    sys.router.asPath.split('#')[0].indexOf('/pc') > -1 ||
    sys.router.asPath.split('#')[0] === '/pc2'
  );
};
export default isPc;
