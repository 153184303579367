import styled from 'styled-components';

const SD_PopupContent = styled.div`
  position: fixed;
  left: 0;
  right: 0;

  bottom: 0px;
  z-index: 1;
  animation-name: popupIntro;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  //animation-timing-function: ease-in-out ;
  animation-duration: 0.54s;
  //overflow: hidden;
`;

/**
 * 팝업세팅할떄 애니메이션이 담긴 루트 컨테이너를 제공함
 */
const AnimationContainer = {
  PopupContent: SD_PopupContent, // SetAnimationContainerHelper.setPopupContents
};

export default AnimationContainer;
