import { ReactNode } from 'react';

import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform: translate(0px);
    opacity: 0;
  }
  100% {
    transform: translate(0px);
    opacity: 1;
  }
`;

// TODO: kangseongofdk
// - PopupContainer 가 bottom 기준으로 생성됨
// - PopupContainer 의 height 100% 가 동작하지 않음
// TODO: kangseongofdk
// - router.go 의 두 번째 인자로 전달하는 값의 변경을 추적할 수 있는지 확인
type SD_OverlayProps = {
  center?: boolean;
  disableOverlayEvent?: boolean;
  overlayColor?: string;
};
const SD_Overlay = styled.div<SD_OverlayProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${(p) =>
    p.center &&
    css`
      justify-content: center;
      align-items: center;
    `}
  touch-action: none;
  ${(p) =>
    p.disableOverlayEvent &&
    css`
      pointer-events: none;
    `}
  background-color: rgba(0, 0, 0, 0.3);
  ${(p) =>
    p.overlayColor &&
    css`
      background-color: ${p.overlayColor};
    `}
  animation: ${fadeIn} 0.1s ease-out;
  cursor: url('/icons/svgFiles/close-fill.svg'), auto;
`;

type SD_EmptyContainerProps = {
  center?: boolean;
};
const SD_EmptyContainer = styled.div<SD_EmptyContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${(p) =>
    p.center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  touch-action: none;

  & > * {
    pointer-events: fill;
    cursor: default;
  }
`;

type SD_ContainerProps = {
  width?: string;
};
const SD_Container = styled.div<SD_ContainerProps>`
  width: 100%;
  ${(p) =>
    !!p.width &&
    css`
      width: ${p.width};
    `}
  cursor: default;
  pointer-events: fill;
  //background-color: #FFF;
`;

export type PopupContainerProps = {
  children: ReactNode[];
  center?: boolean; // 팝업의 가운데 정렬 여부. true 를 주면 오버레이의 한 가운데 위치하게 된다.
  width?: string; // 팝업의 너비
  noContainer?: boolean; // deprecated. 대화상자는 자동으로 true 설정됨. 리팩토링으로 제거 예정
  onClickOverlay?: () => void; // 오버레이의 클릭 이벤트 리스너. 값을 따로 설정하지 않으면 팝업/대화상자를 닫는 리스너가 자동으로 설정된다.

  disableOverlayEvent?: boolean; // 오버레이의 클릭 이벤트가 트리거되지 않도록 함. 오버레이를 클릭하면 오버레이 뒤에 있는 엘리먼트가 클릭됨
  overlayColor?: string; // 오버레이 색상. 설정하지 않으면 기본값 rgba(0, 0, 0, 0.3) 이 설정됨
};
function PopupContainer({
  children,
  center,
  width,
  noContainer,
  onClickOverlay: _onClickOverlay,

  disableOverlayEvent,
  overlayColor,
  ...props
}: PopupContainerProps) {
  const onClickOverlay = (evt) => {
    if (_onClickOverlay && evt.target === evt.currentTarget) {
      _onClickOverlay?.();
    }
  };
  if (noContainer) {
    return (
      <SD_Overlay
        disableOverlayEvent={disableOverlayEvent}
        overlayColor={overlayColor}
        {...props}
      >
        <SD_EmptyContainer
          center={center}
          onClick={onClickOverlay}
        >
          {children}
        </SD_EmptyContainer>
      </SD_Overlay>
    );
  }

  return (
    <SD_Overlay
      // ref={overlayRef}
      center={center}
      onClick={onClickOverlay}
      disableOverlayEvent={disableOverlayEvent}
      overlayColor={overlayColor}
      {...props}
    >
      <SD_Container width={width}>{children}</SD_Container>
    </SD_Overlay>
  );
}

export default PopupContainer;
