import { createElement } from 'react';

import ToastFailClipboard from 'hostSrc/comp/Toast/ToastFailClipboard';
import ToastSuccessClipboard from 'hostSrc/comp/Toast/ToastSuccessClipboard';

import useToastReducer from '../toastManager/useToastReducer';

export default function useShare(toast: ReturnType<typeof useToastReducer>) {
  return {
    async open(url: string) {
      if (!url.startsWith('http')) {
        url = location.origin + url;
      }

      if (globalThis.ReactNativeWebView) {
        globalThis.ReactNativeWebView.postMessage(JSON.stringify({ type: 'share', url }));
      } else {
        try {
          await navigator.clipboard.writeText(url);
          toast.open(createElement(ToastSuccessClipboard));
        } catch (error) {
          toast.open(createElement(ToastFailClipboard));
        }
      }
    },
  };
}
