import typedLocalStorage from 'coreSrc/core/js/utils/typedLocalStorage';

import { utmKeys } from 'hostSrc/analytics/logEvent/utmParameters';

import dayjs from 'dayjs';

function getUtmAdInfo() {
  const utmInfos = utmKeys.map((utmKey) => {
    return { utmKey, storage: typedLocalStorage.get(utmKey) };
  });

  const isFromAd = utmInfos.every((item) => {
    if (!item.storage) {
      return false;
    }
    const now = dayjs();
    const timestamp = dayjs(item.storage.timestamp);
    const diffInDays = timestamp.diff(now, 'day');

    return diffInDays >= -30 && !!item.storage?.value;
  });

  return {
    utmInfos,
    isFromAd,
  };
}

export default getUtmAdInfo;
