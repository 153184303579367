import type { AppContext } from 'next/app';

import { RICHGO_API_HOST } from 'hostSrc/_initDatas/env';

import useRequestMiddleware from './requestMiddleware';
import useResponseMiddleware from './responseMiddleware';

export interface IFetchRichgoApiOptions {
  headers?: Record<string, string>;
  body?: BodyInit | Record<string, any>;
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  signal?: RequestInit['signal'];
}

export default function createRichFetch(appContext?: AppContext) {
  async function richFetch(url: string, options: IFetchRichgoApiOptions = {}) {
    if (url.startsWith('/')) {
      url = RICHGO_API_HOST + url;
    }
    await useRequestMiddleware({ url, options, appContext });

    const response = await fetch(url, options as RequestInit);

    return await useResponseMiddleware({ response, appContext });
  }

  return richFetch;
}
