import { API_MOCKING, isLocal } from 'hostSrc/_initDatas/env';

import { handlers } from 'mocks/handlers';

import createRichFetch, {
  IFetchRichgoApiOptions,
} from '../../../coreSrc/core/js/utils/richFetch/createRichFetch';

import { initMocks } from 'mocks';

const _richFetch = createRichFetch();

const useMock = API_MOCKING === 'enabled' && handlers.length > 0;
const promise = useMock ? initMocks() : Promise.resolve();

type CustomFetchParameter = {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  params?: Record<string, any>;
  data?: BodyType<unknown>;
  headers?: Record<string, string>;
  signal?: AbortSignal;
};

type CustomFetchOptions = {
  serverSideFetch?: ReturnType<typeof createRichFetch>;
  headers?: Record<string, string>;
  signal?: AbortSignal;
};
export const customFetch = async <ResponseType>(
  { url, method, params, data, headers, signal }: CustomFetchParameter,
  options?: CustomFetchOptions
) => {
  if (useMock && isLocal) {
    await promise;
  }

  const richFetch = options?.serverSideFetch ?? _richFetch;

  headers = headers instanceof Headers ? Object.fromEntries(headers.entries()) : headers;
  if (options?.headers) {
    options.headers =
      options.headers instanceof Headers
        ? Object.fromEntries(options.headers.entries())
        : options.headers;
  }

  if (params && 'offset' in params) {
    params.offset ??= 0;
  }

  const response = await richFetch(url + '?' + new URLSearchParams(params), {
    method: method.toUpperCase() as IFetchRichgoApiOptions['method'],
    headers: { ...headers, ...options?.headers },
    signal: options?.signal ?? signal,
    ...(data ? { body: JSON.stringify(data) } : {}),
  });

  const result = (await response.json()) as TmpApiResponse<ResponseType>;

  if (!response.ok) {
    const errorData: ErrorType<typeof result> = {
      status: response.status,
      message: result?.responseException || `Network Error ${response.status}`,
      name: 'Network Error',
      data: result,
    };

    throw errorData;
  }

  return result;
};

export default customFetch;

export type ErrorType<ErrorData> = {
  status: number;
  message: string;
  name: string;
  data: ErrorData;
};

export type BodyType<BodyData> = BodyData;

// 임시로 래핑 타입 만들어둠 백엔드쪽에서 명세 수정해주는게 좋을 듯
export type TmpApiResponse<T> = {
  version?: string | null;
  statusCode?: number;
  message?: string | null;
  isError?: boolean | null;
  responseException?: string | null;
  result?: T;
};
