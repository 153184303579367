import { useEffect, useState } from 'react';

import type { NextPageContext } from 'next';
import App, { AppContext } from 'next/app';
import dynamic from 'next/dynamic';

import useAnalytics from 'coreSrc/base/analytics/useAnalytics';
import SystemContext from 'coreSrc/base/systemManager/context';
import { PopupLayer } from 'coreSrc/base/systemManager/routeManager';
import useSystemReducer from 'coreSrc/base/systemManager/useSystemReducer';
import type User from 'coreSrc/base/userSystemManager/user/User';
import useUserSystemManager from 'coreSrc/base/userSystemManager/user/useUserSystemReducer';
import UserContext from 'coreSrc/base/userSystemManager/user/userContext';
import ContextWindows from 'coreSrc/base/window/ContextWindows';

import useHistoryIdx from 'coreSrc/core/js/hooks/useHistoryIdx';
import useIsomorphicLayoutEffect from 'coreSrc/core/js/hooks/useIsomorphicLayoutEffect';
import useWebviewRedirect from 'coreSrc/core/js/hooks/useWebviewRedirect';
import RnManager from 'coreSrc/core/js/utils/RnManager';
import type createRichFetch from 'coreSrc/core/js/utils/richFetch/createRichFetch';
import typedLocalStorage from 'coreSrc/core/js/utils/typedLocalStorage';

import { REACT_QUERY_DEVTOOLS, isLocal } from 'hostSrc/_initDatas/env';
import GlobalStyleSheet from 'hostSrc/_initDatas/styles/global';
import { type UtmParameters, utmKeys } from 'hostSrc/analytics/logEvent/utmParameters';
import useAdsOnAndroidExit from 'hostSrc/comp/Advert/useAdsOnAndroidExit';

import Device from './Device';
import Partner from './Partner';
import WebAppHead from './WebAppHead';
import WebAppLoading from './WebAppLoading';
import useAppContextMiddleware from './middleware';
import normalTheme from './normalTheme';

import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
const HOTJAR_SV = process.env.NEXT_PUBLIC_HOTJAR_SV;

const RequireAppUpdate = dynamic(() => import('hostSrc/comp/RequireAppUpdate'), { ssr: false });

const TermAgreedDrawer = dynamic(
  () => import('hostSrc/screens/SignUpScreen/TermAgreed/TermAgreedDrawer'),
  {
    ssr: false,
  }
);

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'GmarketSansMedium';
    font-weight: normal;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  }

`;

export default function WebApp({
  Component,
  pageProps,
  userAgent,
  partner,
  user,
  router,
  utmParameters,
}) {
  const system = useSystemReducer(userAgent, partner);
  const userSystem = useUserSystemManager(user);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
          },
        },
      })
  );

  const isPc = !system.detector.isMobile;

  useEffect(() => {
    const utmParameterInfo = (utmParameters || {}) as UtmParameters;

    // 모든 필수 UTM 파라미터가 있는지 검증
    const hasAllParameters = utmKeys.every((key) => utmParameterInfo[key]);

    if (hasAllParameters) {
      utmKeys.forEach((key) => {
        // 값이 있으면 저장
        typedLocalStorage.set(key, {
          value: utmParameterInfo[key],
          timestamp: dayjs().toISOString(),
        });
      });
    }
  }, []);

  useWebviewRedirect(system, {
    userId: userSystem.profile.getId() || '',
    email: userSystem.profile.getEmail() || '',
    nickName: userSystem.profile.getName() || '',
    deviceId: system.router?.query?.deviceId || '',
  });

  useHistoryIdx();

  useAnalytics();
  useAdsOnAndroidExit(system);

  useEffect(() => {
    async function initializeHotJar() {
      const hotjar = (await import('react-hotjar')).hotjar;
      hotjar.initialize(Number(HOTJAR_ID), Number(HOTJAR_SV));
    }

    if (process.env.NODE_ENV === 'production') {
      initializeHotJar();
    }
  }, []);

  useIsomorphicLayoutEffect(() => {}, []);

  useEffect(() => {
    if (userSystem.auth.getIsLoggedIn() === true) {
      // 처음 앱 실행시 이미 로그인 된 유저라면 푸시알림을 받기위해 정보를 주입해줘야함
      RnManager.updateAccessToken();
    }
    // console.table(system);
    console.group('_app useEffect SYSTEM');
    if (!system.detector.isSSR) {
      const directYn = sessionStorage.getItem('directYn');
      if (directYn === null) sessionStorage.setItem('directYn', 'true');
      else if (directYn === 'true') sessionStorage.setItem('directYn', 'false');
      console.log('sessionStorage', system.detector.isSSR, sessionStorage);
    }

    for (const k in system) {
      console.group(`${k}`);
      console.log(system[k]);
      console.groupEnd();
    }
    console.groupEnd();
  }, []);

  return (
    <>
      <GlobalFonts />
      <QueryClientProvider client={queryClient}>
        <SystemContext.Provider value={system}>
          <UserContext.Provider value={userSystem}>
            <Hydrate state={pageProps.dehydratedState}>
              <ThemeProvider theme={normalTheme}>
                <WebAppHead pageProps={pageProps} />
                <ContextWindows.Provider value={system.windows}>
                  <Component {...pageProps} />
                  <GlobalStyleSheet
                    isPc={isPc}
                    isIOS={system.detector.isIOS}
                    isMobile={system.detector.isMobile}
                  />
                  <PopupLayer />
                  <WebAppLoading hidden={!userSystem.loading} />
                  <Partner />
                  <Device />
                  <TermAgreedDrawer />
                  <RequireAppUpdate />
                </ContextWindows.Provider>
              </ThemeProvider>
            </Hydrate>
            {REACT_QUERY_DEVTOOLS === 'enabled' && isLocal && <ReactQueryDevtools />}
          </UserContext.Provider>
        </SystemContext.Provider>
      </QueryClientProvider>
    </>
  );
}

export interface IWebAppPageContext extends NextPageContext {
  richFetch: ReturnType<typeof createRichFetch>;
  user: { userAgent: string } & Partial<User>;
}

export interface IWebAppContext extends AppContext {
  ctx: IWebAppPageContext;
}

WebApp.getInitialProps = async (appContext: AppContext) => {
  await useAppContextMiddleware(appContext);

  const appProps = await App.getInitialProps(appContext);

  Object.assign(appProps, {
    userAgent:
      (appContext.ctx.req ? appContext.ctx.req.headers['user-agent'] : navigator.userAgent) ?? '',
    partner: appContext.ctx['partner'],
    user: appContext.ctx['user'],
    ads: appContext.ctx['ads'],
    utmParameters: appContext.ctx['utmParameters'],
  });

  return appProps;
};
