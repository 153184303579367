import richFetch from 'coreSrc/core/js/utils/richFetch';

const fcmTokenManager = {
  login() {
    if (globalThis.richgoWebview) {
      try {
        const fcmToken = sessionStorage.getItem('fcmToken') || null;
        const deviceId = sessionStorage.getItem('deviceId') || null;
        const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

        if (fcmToken) {
          richFetch('/user/app-login/complete', {
            method: 'POST',
            body: { deviceId, fcmToken, isAndroid },
          });
        }
        if (isAndroid) {
          globalThis.richgoWebview.postMessage({ type: 'updateAndroidForegroundNotification' });
        }
      } catch (error) {
        console.error(error);
      }
    }
  },

  logout() {
    if (globalThis.richgoWebview) {
      try {
        const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
        const fcmToken = sessionStorage.getItem('fcmToken') || null;
        const deviceId = sessionStorage.getItem('deviceId') || null;

        if (fcmToken) {
          richFetch('/user/logout/v2', {
            method: 'DELETE',
            body: { isAndroid, fcmToken, deviceId },
          });
        }
        if (isAndroid) {
          globalThis.richgoWebview.postMessage({ type: 'updateAndroidForegroundNotification' });
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
};

export default fcmTokenManager;
